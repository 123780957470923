import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

export default () => {
  return (
    <Layout>
      <div className="jumbotron">
        <Link to="/" className="closeX menuX"><span>X</span></Link>
        <Menu localPath="/warum" />
        <h1>Warum schreibt man ein OpenBook?</h1>
        <p className="jumbotron-text">
         Was nicht unmittelbar nachvollziehbar scheint – zum Beispiel über zwei Jahre lang ein Unterrichtswerk zu Johann Sebastian Bach auszuarbeiten, um es dann anschließend zu verschenken –, lässt sich meist einfach verstehen, wenn sich ein paar gängige Vorurteile ausräumen lassen.
        </p>
      </div>
      <div className="gridBox">
        <div className="gridFraction">
          <img src="/images/Verkehrszeichen-Fragezeichen.png" />
        </div>
        <div className="gridFraction">
          <b>1. Vorurteil: Mit Bücherschreiben verdient man Geld.</b><br/>
          Musikpädagogische Bücher werden nicht aus finanziellen Gründen geschrieben, dazu ist der Markt viel zu klein. Nehmen wir einmal an, Sie würden ein Jahr lang täglich zwei Stunden an einem Unterrichtsheft im Umfang von 40 Seiten arbeiten und fänden einen namhaften Verlag, der es in einer freien Reihe drucken würde. Dann bekämen Sie natürlich als Bezahlung kein festes Honorar, sondern zwischen 5% und 10% vom Honorarbemessungspreis, der sich aus dem Ladenpreis ohne Mehrwertsteuer abzüglich des artikel-üblichen Rabattes für Buchhändler errechnet. Nehmen wir weiter an, Sie bekämen volle 10% und das Heft würde sich zum Honorarbemessungspreis von 15 EUR prächtig verkaufen, dann würden Sie für 2000 verkaufte Exemplare pro Jahr 1.800 EUR Autorenhonorar bekommen, das allerdings noch zu versteuern wäre. Ihnen blieben also ungefähr 1.200 EUR, dass heißt, für ungefähr 600 Arbeitsstunden kämen Sie auf einen Stundenlohn von 2 EUR! Sollte sich Ihr Heft dann drei Jahre in den Läden halten (wovon Sie ausgehen können) und sich jedes Jahr auf's Neue prächtig verkaufen (wovon Sie nicht ausgehen sollten), läge ihr Honorar immerhin schon in der Mindestlohnregion für die Abfallwirtschaft. Rechnen Sie selbst, ab wann Sie also ›verdienen‹…
        </div>
        <div className="gridFraction">
          <img src="/images/Verkehrszeichen-Stop.png" />
        </div>
        <div className="gridFraction">
          <b>2. Vorurteil: Der Autor schreibt ein Werk, der Verlag kümmert sich um den Rest.</b><br/>
          Das mag einmal richtig gewesen sein (und vielleicht sogar bei einigen wenigen Verlagen auch heute noch zutreffen). Doch leider werden Autoren immer häufiger zu mehr verpflichtet, weil sich sonst die Sache für den Verlag "nicht rechnet". Das fängt üblicherweise an bei Notenbeispielen, kann das Besorgen von Bildern und Bildrechten einschließen, natürlich werden Sie die CDs für die Soundbeispiele aus eigener Tasche bezahlen und im ungünstigen Fall dazu verpflichtet, eine PDF-Datei mit druckfertigem Layout abzuliefern. Noch schlechter kann es Sie treffen, wenn Sie eine Forschungsarbeit publizieren wollen (oder müssen), denn dann zahlen Sie die Druckkosten in der Regel auch noch selbst und der Verlag übernimmt lediglich Lagerung, Werbung und Auslieferung des Werkes (da Ihr Titel in diesem Fall vom Verlag nur in Kommission vertrieben wird, kann man ihn ein paar Monate später bei Google-Books online lesen und in großen Teilen kostenlos herunterladen).
        </div>
        <div className="gridFraction">
          <img src="/images/Verkehrszeichen-Umleitung.png" />
        </div>
        <div className="gridFraction">
          <b>3. Vorurteil: Verlage sind an guten und neuen Inhalten interessiert.</b><br/>
          Verlage sind an Inhalten interessiert, die sich gut verkaufen und das sind leider nicht immer gute Inhalte. Anders lässt sich kaum erklären, warum man auch in neuesten Schulbüchern fachwissenschaftlich Überholtes und im schlimmsten Fall sogar lexikalisch Falsches lesen kann (vgl. hierzu S. 10–12 und S. 25). Was sich gestern in der Praxis bewährt hat, wird von Praktikern für die heutige Praxis in Kenntnis der Lehrpläne von morgen neu aufbereitet und publiziert. Ein funktionierender Kreislauf, der gänzlich autonom produziert und sich kaum durch Fachwissenschaften irritieren lässt.
        </div>
      </div>
      <p>
      Warum also schreibt man OpenBooks? Man schreibt OpenBooks, weil man gerne Bücher schreibt und sie auch layouten kann (vielen Dank an alle Verlage), weil man keine Kompromisse mehr bei den Inhalten eingehen möchte und weil man hofft, mehr Interessierte über Bildungsportale und Internet-Tauschbörsen zu erreichen als über die Regale der Fachbuchhandlungen. Damit die Texte wachsen können, benötigen sie natürlich ein freundliches Umfeld. In meinem Fall waren das zum einen die "Lektoren" der "Kritikerrunde" (Verena Wied, Verena Weidner, Kilian Sprau und Andreas Helmberger), zum anderen Brilliant Classics, das Bachhaus Eisenach, Armin Schidlo, Erke Freisler, Volker Ansorge und nicht zu vergessen: meine Frau Regina.<br/><br/>
      Sollte Ihnen dieses OpenBook zu Johann Sebastian Bach gefallen, dürfen Sie mich gerne ›entlohnen‹, indem Sie es möglichst oft im Unterricht verwenden, mir freundliche Kritik oder einfach nur ein Dankeschön über meine Website zukommen lassen.<br/><br/>
      Karlsfeld 2011
      </p>
      <p style={{ 'fontSize': '0.8em', 'textAlign': 'right' }}>
        Aus: Johann Sebastian Bach. Ein Superstar gestern und heute. Karlsfeld 2011, Kommentarheft, Vorwort S. 4.
      </p>
      <Helmet>
        <title>OER | Ulrich Kaiser - Warum?</title>
        <meta name="description" content="Hintergrundinformationen zur Motivation von Ulrich Kaiser, Open Educational Resources zu publizieren" />
        <meta name="keywords" content="Hintergründe, OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
    </Layout>
  )
}
